<template>
  <div class="error-page-container">
    <div class="error-page-content-container">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'errorLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.error_title')}`,
    titleTemplate: `%s`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.error_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.error_keyword')}` }]
  }
}
</script>
<style lang="scss">
.error-page-container {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
